export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'licencas',
      displayName: 'menu.licencas',
      meta: {
        icon: 'fa-key',
      },
    },
    {
      name: 'compras',
      displayName: 'menu.compras',
      meta: {
        icon: 'fa-credit-card',
      },
    },
    {
      name: 'bacbo',
      displayName: 'Bac-Bo',
      meta: {
        icon: 'fa-gamepad',
      },
    },
    {
      name: 'ajuda',
      displayName: "Ajuda",
      meta: {
        icon: 'fa-question-circle-o',
      },
    },
  ],
}
