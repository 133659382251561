import { VuesticPlugin, useGlobalConfig } from 'vuestic-ui';
import { createApp } from 'vue'
import { createGtm } from 'vue-gtm'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import moment from 'moment'
  
//DEVEXTREME
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.material.blue.light.compact.css'

const i18nConfig = {
  locale: 'br',
  fallbackLocale: 'br',
  messages: {
    en: require('@/i18n/en.json'),
    ch: require('@/i18n/cn.json'),
    es: require('@/i18n/es.json'),
    ir: require('@/i18n/ir.json'),
    br: require('@/i18n/br.json')
  }
}

router.beforeEach((to, from, next) => {
  let bUserLogado = false;
  let xUserLogado = localStorage.getItem('userLogado')

  if (xUserLogado != "true")
    bUserLogado = false;
  else
    bUserLogado = true;
  
  if ((bUserLogado == false) && to.meta.requiresAuth == true) {
    return next('/auth/login')
  }

  store.commit("changeUserDados", localStorage.getItem('userDados'))
  next()
  
})

const app = createApp(App)
app.config.globalProperties.moment = moment;
app.use(store)
app.use(router)
if (process.env.VUE_APP_GTM_ENABLED === 'true') {
  const gtmConfig = {
    id: process.env.VUE_APP_GTM_KEY,
    debug: false,
    vueRouter: router,
  }
  app.use(createGtm(gtmConfig))
}
app.use(createI18n(i18nConfig))
app.use(VuesticPlugin, vuesticGlobalConfig)
app.mount('#app')
