import { createStore } from 'vuex'

export default createStore({
  strict: true, // process.env.NODE_ENV !== 'production',
  state: {
    isSidebarMinimized: false,
    userLogado: false,
    userDados: {
      id : 0,
      nome : "John Doe",
      email : "email@email.com",
      token: ""
    }
  },
  mutations: {
    updateSidebarCollapsedState(state, isSidebarMinimized) {
      state.isSidebarMinimized = isSidebarMinimized
    },
    changeUserDados(state, newUserDados) {
      state.userDados = newUserDados
      console.log("state.userDados alterdo em store = ", JSON.stringify(state.userDados))
    },
    changeUserLogado(state, bVal) {
      state.userLogado = bVal
      localStorage.setItem('userLogado', bVal)
    }
  },
})
